import React from 'react';
import { Menu, Phone, MapPin, Star, ArrowRight } from 'lucide-react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

// Örnek JSON verisini component dışında tanımlayalım
const siteData = {
  "sector": "berber",
  "language": "TR",
  "company_address": "Büyükdere Cad. No:123, Şişli, İstanbul",
  "company_phone": "+90 212 345 67 89",
  "company_name": "YASİN USTA",
  "site_title": "YASİN USTA: Berber Zanaati",
  "site_keywords": "berber, saç kesimi, sakal bakımı, tıraş, İstanbul, kuaför, stil, bakım, YASİN USTA, güzellik, moda",
  "site_description": "YASİN USTA, İstanbul'da modern berber hizmeti sunan, kaliteli ürünler ve deneyimli ustalarla kusursuz bir deneyim yaşatıyor. Randevu alın!",
  "hero_section": {
    "title": "YASİN USTA: Berberliğin Yeni Adresi",
    "description": "Deneyimli ustalarımız ve kaliteli ürünlerimizle, size en iyi berber deneyimini sunuyoruz. Şıklığınızı ve kendinizi YASİN USTA'ta keşfedin!"
  },
  "articles": [
    {
      "title": "En İyi Berber Hizmetleri İçin Kılavuz",
      "content": "Saç ve sakal bakımında uzmanlaşmış YASİN USTA, modern teknikleri geleneksel berberliğin sıcaklığıyla birleştiriyor. Deneyimli ekibimiz, kişiye özel çözümlerle en iyi sonucu elde etmenizi sağlıyor.  YASİN USTA'ta, kaliteli ürünler ve konforlu bir ortamda, kendinizi şımartın."
    },
    {
      "title": "YASİN USTA ile Kusursuz Bir Görünüm",
      "content": "YASİN USTA farkıyla kusursuz bir görünüme kavuşun!  Profesyonel ekibimiz, en son trendleri takip ederek, size en uygun saç ve sakal stilini yaratıyor.  YASİN USTA, sadece bir berber değil, stil danışmanınızdır."
    },
    {
      "title": "Berberlik Sanatının Hikayesi: YASİN USTA",
      "content": "YASİN USTA, berberliğin zengin mirasını modern tekniklerle harmanlayan bir mekandır.  Ustalarımızın titiz çalışması ve misafirlerine gösterdikleri özen, YASİN USTA'u benzersiz kılıyor.  Gelin, YASİN USTA'un eşsiz atmosferini deneyimleyin."
    }
  ],
  "company_attributes": [
    {
      "property": "Deneyimli Ustalar",
      "description": "YASİN USTA'ta, alanında uzman ve deneyimli berberlerle çalışıyoruz. Her bir ustamız, müşterilerimize en iyi hizmeti sunmak için sürekli olarak kendilerini geliştiriyorlar."
    },
    {
      "property": "Kaliteli Ürünler",
      "description": "En iyi markaların kaliteli ürünlerini kullanarak, saç ve sakalınıza en uygun bakımı sağlıyoruz.  Ürünlerimizi özenle seçiyor ve müşterilerimizin ihtiyaçlarını karşılayan çeşitliliğe sahibiz."
    },
    {
      "property": "Kişiye Özel Hizmet",
      "description": "Her müşterimiz bizim için özeldir. YASİN USTA'ta, ihtiyaçlarınıza ve tarzınıza uygun kişiye özel hizmetler sunuyoruz.  Size en uygun saç ve sakal modelini birlikte belirliyor ve uyguluyoruz."
    },
    {
      "property": "Modern ve Konforlu Ortam",
      "description": "Rahat ve modern bir ortamda, saç ve sakal bakımınızı keyifli bir deneyime dönüştürüyoruz.  YASİN USTA'ta, kendinizi evinizde hissedeceksiniz."
    },
    {
      "property": "Uygun Fiyatlar",
      "description": "Kaliteli hizmeti uygun fiyatlarla sunuyoruz.  YASİN USTA'ta, bütçenizi zorlamadan kusursuz bir görünüme kavuşabilirsiniz."
    },
    {
      "property": "Müşteri Memnuniyeti",
      "description": "Müşteri memnuniyeti bizim için önceliklidir.  YASİN USTA olarak, her müşterimizin beklentilerini karşılamak ve memnuniyetini sağlamak için sürekli olarak çalışıyoruz."
    }
  ],
  "services": [
    {
      "property": "Saç Kesimi",
      "description": "Erkek saç kesimi konusunda uzmanlaşmış ekibimiz, en son trendleri takip ederek size en uygun stili yaratıyor.  Müşteri memnuniyeti odaklı yaklaşımımızla, beklentilerinizi aşmayı hedefliyoruz."
    },
    {
      "property": "Sakal Bakımı",
      "description": "Sakalınızı şekillendirmek ve bakımını yapmak için profesyonel destek sunuyoruz.  Sakalınıza en uygun ürünleri kullanarak, sağlıklı ve bakımlı bir görünüm sağlıyoruz."
    },
    {
      "property": "Tıraş",
      "description": "Geleneksel tıraş yöntemleri ve modern tekniklerle, size rahatlatıcı ve konforlu bir tıraş deneyimi sunuyoruz.  Cildinizi korumaya özen göstererek, en iyi sonucu elde ediyoruz."
    },
    {
      "property": "Saç ve Sakal Boyama",
      "description": "Saç ve sakal boyama hizmetlerimizde, en kaliteli ürünleri kullanıyoruz.  Doğal renkler ve modern teknikler ile size en uygun rengi buluyoruz."
    }
  ],
  "reviews": [
    {
      "property": "Ayşe Yılmaz",
      "description": "YASİN USTA'ta harika bir deneyim yaşadım! Berberler çok profesyonel ve ilgiliydi. Kesinlikle tavsiye ederim."
    },
    {
      "property": "Mehmet Öz",
      "description": "Fiyatlar biraz yüksek olsa da, hizmet kalitesi gerçekten çok iyi.  Saç kesimimden çok memnun kaldım."
    },
    {
      "property": "Fatma Demir",
      "description": "Ortam çok güzel ve rahatlatıcı. Berberler çok cana yakın ve işlerinde uzman. Tekrar geleceğim."
    },
    {
      "property": "Ali Kaya",
      "description": "Sakal bakım hizmeti mükemmeldi.  Tavsiye ederim."
    },
    {
      "property": "Zeynep Şahin",
      "description": "Randevu sisteminin biraz daha iyileştirilmesi gerekiyor. Ama genel olarak memnun kaldım."
    }
  ],
  "faqs": [
    {
      "property": "Randevu almak için nasıl iletişime geçebilirim?",
      "description": "Telefon numaramızdan veya internet sitemizden online randevu alabilirsiniz."
    },
    {
      "property": "Ödeme yöntemleriniz nelerdir?",
      "description": "Nakit, kredi kartı ve banka kartıyla ödeme yapabilirsiniz."
    },
    {
      "property": "Hizmetlerinizin fiyatları nedir?",
      "description": "Fiyat listemiz internet sitemizde mevcuttur.  Detaylı bilgi için bizimle iletişime geçebilirsiniz."
    }
  ],
  "buttons": [
    {
      "original": "Submit",
      "translated": "Gönder"
    },
    {
      "original": "Cancel",
      "translated": "İptal"
    }
  ]
};

const MetaTags = ({ data }) => {
  React.useEffect(() => {
    document.title = data.site_title;
    const metaDescription = document.querySelector('meta[name="description"]');
    const metaKeywords = document.querySelector('meta[name="keywords"]');
    
    if (metaDescription) metaDescription.content = data.site_description;
    if (metaKeywords) metaKeywords.content = data.site_keywords;
  }, [data]);

  return null;
};

const Layout = ({ data }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <div className="min-h-screen bg-white">
      <MetaTags data={data} />
      <header className="bg-white shadow-md sticky top-0 z-50">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            <Link to="/" className="flex items-center">
              <span className="text-2xl font-bold text-gray-800">{data.company_name}</span>
            </Link>
            
            <div className="md:hidden">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="p-2">
                <Menu className="h-6 w-6" />
              </button>
            </div>

            <nav className="hidden md:flex space-x-8">
              <Link to="/" className="text-gray-600 hover:text-blue-600">Ana Sayfa</Link>
              <Link to="/services" className="text-gray-600 hover:text-blue-600">Hizmetler</Link>
              <Link to="/articles" className="text-gray-600 hover:text-blue-600">Makaleler</Link>
              <Link to="/about" className="text-gray-600 hover:text-blue-600">Hakkımızda</Link>
              <Link to="/contact" className="text-gray-600 hover:text-blue-600">İletişim</Link>
            </nav>
          </div>
        </div>

        {isMenuOpen && (
          <div className="md:hidden bg-white border-t">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <Link to="/" className="block px-3 py-2 text-gray-600 hover:text-blue-600">Ana Sayfa</Link>
              <Link to="/services" className="block px-3 py-2 text-gray-600 hover:text-blue-600">Hizmetler</Link>
              <Link to="/articles" className="block px-3 py-2 text-gray-600 hover:text-blue-600">Makaleler</Link>
              <Link to="/about" className="block px-3 py-2 text-gray-600 hover:text-blue-600">Hakkımızda</Link>
              <Link to="/contact" className="block px-3 py-2 text-gray-600 hover:text-blue-600">İletişim</Link>
            </div>
          </div>
        )}
      </header>

      <main>
        <Routes>
          <Route path="/" element={<Home data={data} />} />
          <Route path="/services" element={<Services data={data} />} />
          <Route path="/articles" element={<Articles data={data} />} />
          <Route path="/about" element={<About data={data} />} />
          <Route path="/contact" element={<Contact data={data} />} />
        </Routes>
      </main>

      <Footer data={data} />
    </div>
  );
};

const Home = ({ data }) => (
  <>
    <section className="bg-blue-600 text-white py-16">
      <div className="max-w-6xl mx-auto px-4 text-center">
        <h1 className="text-4xl md:text-5xl font-bold mb-4">{data.hero_section.title}</h1>
        <p className="text-xl mb-8">{data.hero_section.description}</p>
        <Link to="/contact" className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 inline-flex items-center gap-2">
          Randevu Al <ArrowRight className="w-4 h-4" />
        </Link>
      </div>
    </section>

    <section className="py-16">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Özelliklerimiz</h2>
        <div className="grid md:grid-cols-3 gap-8">
          {data.company_attributes.slice(0, 3).map((attr, index) => (
            <div key={index} className="bg-gray-50 p-6 rounded-lg">
              <h3 className="text-xl font-semibold mb-4">{attr.property}</h3>
              <p className="text-gray-600">{attr.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>

    <section className="bg-gray-50 py-16">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Müşteri Yorumları</h2>
        <div className="grid md:grid-cols-3 gap-8">
          {data.reviews.map((review, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
              <div className="flex flex-col h-full">
                <div className="flex-1">
                  <p className="text-gray-600 mb-4">{review.description}</p>
                </div>
                <div className="flex items-center mt-4">
                  <div className="flex-1">
                    <p className="font-semibold">{review.property}</p>
                  </div>
                  <div className="flex gap-1">
                    {[...Array(5)].map((_, i) => (
                      <Star key={i} className="w-4 h-4 text-yellow-400 fill-current" />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  </>
);

const Services = ({ data }) => (
  <div className="py-16">
    <div className="max-w-6xl mx-auto px-4">
      <h1 className="text-4xl font-bold text-center mb-12">Hizmetlerimiz</h1>
      <div className="grid md:grid-cols-2 gap-8">
        {data.services.map((service, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <h2 className="text-2xl font-semibold mb-4">{service.property}</h2>
            <p className="text-gray-600">{service.description}</p>
            <button className="mt-4 text-blue-600 flex items-center gap-2 hover:text-blue-800">
              Detaylı Bilgi <ArrowRight className="w-4 h-4" />
            </button>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const Articles = ({ data }) => (
  <div className="py-16">
    <div className="max-w-6xl mx-auto px-4">
      <h1 className="text-4xl font-bold text-center mb-12">Makaleler</h1>
      <div className="grid md:grid-cols-2 gap-8">
        {data.articles.map((article, index) => (
          <article key={index} className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold mb-4 hover:text-blue-600 cursor-pointer">
              {article.title}
            </h2>
            <p className="text-gray-600 mb-4 line-clamp-3">{article.content}</p>
            <button className="text-blue-600 flex items-center gap-2 hover:text-blue-800">
              Devamını Oku <ArrowRight className="w-4 h-4" />
            </button>
          </article>
        ))}
      </div>
    </div>
  </div>
);

const About = ({ data }) => (
  <div className="py-16">
    <div className="max-w-6xl mx-auto px-4">
      <h1 className="text-4xl font-bold text-center mb-12">{data.company_name} Hakkında</h1>
      <div className="prose max-w-none mb-12">
        <p className="text-lg text-gray-600 text-center mb-12">{data.site_description}</p>
      </div>
      <div className="grid md:grid-cols-2 gap-8">
        {data.company_attributes.map((attr, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <h2 className="text-2xl font-semibold mb-4">{attr.property}</h2>
            <p className="text-gray-600">{attr.description}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const Contact = ({ data }) => {
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    message: ''
  });
  const [showSuccess, setShowSuccess] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Burada normalde bir API çağrısı olacak
    // Şimdilik simüle ediyoruz
    setTimeout(() => {
      console.log('Form data:', formData);
      setShowSuccess(true);
      setFormData({ name: '', email: '', message: '' }); // Formu temizle
      
      // 3 saniye sonra başarı mesajını kaldır
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
    }, 500);
  };

  return (
    <div className="py-16 relative">
      {/* Başarı Popup'ı */}
      {showSuccess && (
        <div className="fixed top-4 right-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded-lg shadow-lg z-50 animate-fade-in">
          <div className="flex items-center">
            <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
            </svg>
            <span className="font-medium">Mesajınız başarıyla gönderildi!</span>
          </div>
        </div>
      )}

      <div className="max-w-6xl mx-auto px-4">
        <h1 className="text-4xl font-bold text-center mb-12">İletişim</h1>
        <div className="grid md:grid-cols-2 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="space-y-6">
              <div className="flex items-center gap-4">
                <MapPin className="w-6 h-6 text-blue-600 flex-shrink-0" />
                <span>{data.company_address}</span>
              </div>
              <div className="flex items-center gap-4">
                <Phone className="w-6 h-6 text-blue-600 flex-shrink-0" />
                <span>{data.company_phone}</span>
              </div>
              
              <div className="mt-8">
                <h2 className="text-2xl font-semibold mb-6">Sık Sorulan Sorular</h2>
                <div className="space-y-6">
                  {data.faqs.map((faq, index) => (
                    <div key={index} className="bg-gray-50 p-4 rounded-lg">
                      <h3 className="font-semibold text-lg mb-2">{faq.property}</h3>
                      <p className="text-gray-600">{faq.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Ad Soyad</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">E-posta</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Mesajınız</label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows="4"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors duration-300 flex items-center justify-center"
              >
                {data.buttons.find(btn => btn.original === "Submit")?.translated}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const Footer = ({ data }) => (
  <footer className="bg-gray-800 text-white py-12">
    <div className="max-w-6xl mx-auto px-4">
      <div className="grid md:grid-cols-3 gap-8">
        <div>
          <h3 className="text-lg font-semibold mb-4">{data.company_name}</h3>
          <p className="text-gray-300">{data.site_description}</p>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4">İletişim</h3>
          <div className="space-y-2 text-gray-300">
            <p className="flex items-center gap-2">
              <MapPin className="w-4 h-4" />
              {data.company_address}
            </p>
            <p className="flex items-center gap-2">
              <Phone className="w-4 h-4" />
              {data.company_phone}
            </p>
          </div>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4">Hızlı Bağlantılar</h3>
          <nav className="space-y-2">
            <Link to="/services" className="block text-gray-300 hover:text-white">Hizmetler</Link>
            <Link to="/articles" className="block text-gray-300 hover:text-white">Makaleler</Link>
            <Link to="/about" className="block text-gray-300 hover:text-white">Hakkımızda</Link>
            <Link to="/contact" className="block text-gray-300 hover:text-white">İletişim</Link>
          </nav>
        </div>
      </div>
      <div className="mt-8 pt-8 border-t border-gray-700 text-center text-gray-300">
        <p>&copy; {new Date().getFullYear()} {data.company_name}. Tüm hakları saklıdır.</p>
      </div>
    </div>
  </footer>
);

const App = () => {
  return (
    <Router>
      <Layout data={siteData} />
    </Router>
  );
};

export default App;